// 表头图标配置
let headData = {
  title: '账本列表',
  items: [
    /* 新增按钮 */
    {
      style: 'button',
      label: '查看更多',
      size: 'medium',
      type: 'text',
      clickEvent: (thisVue) => {
        thisVue.toLedger()
      }
    }
  ]
}

// 列表columns配置
let columnsData = [
  {
    label: '序号',
    type: 'index',
    align: 'center',
    show: true,
    width: 90
  },
  {
    label: '分片名',
    prop: 'shardName',
    show: true,
    align: 'center',
    minWidth: 130
  },
  {
    label: '账本类型',
    prop: 'ledgerCodeName',
    show: true,
    align: 'center',
    minWidth: 130
  },
  {
    label: '创建时间',
    prop: 'timestamp',
    align: 'center',
    show: true,
    enableSlot: true,
    minWidth: 200
  },
  {
    label: '账本哈希',
    prop: 'hash',
    show: true,
    enableSlot: true,
    minWidth: 600
  }
]

// 分页配置项
let pageData = {
  size: 10, // 每页的数据量
  total: 0, // 数据总量
  layout: 'prev, pager, next, total, jumper, slot',
  currentPage: 1 // 当前页
}

let tableObjListLedger = {
  'columnsData': columnsData, // 表格列/操作列配置
  'headData': headData, // 表头图标配置
  'pageData': pageData // 分页数据
}

export default tableObjListLedger
