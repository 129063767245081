<template>
    <div class="echart-comp" v-loading="loading">
        <!--折线-->
        <div v-if="!this.datas || this.datas.length === 0" class="nodata">暂无数据</div>
        <echart width="100%" height="100%" :options="options"></echart>
    </div>
</template>

<script>
import Echart from '@/components/Echart/index.vue'

export default {
  name: 'bar-chart',
  components: {
    Echart
  },
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    processingType: {
      type: String,
      default: () => 'bar'
    }
  },
  data() {
    return {
      options: {},
      xData: [],
      yData: [],
      loading: false
    }
  },
  computed: {},
  watch: {
    datas: {
      handler() {
        this.init()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      this.xData = []
      this.yData1 = []
      this.yData2 = []
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          this.xData.push(item.key)
          this.yData1.push(item.value ? item.value : 0)
          // this.yData2.push(item.data.loss ? item.data.loss : 0)
        })
      }
      this.options = {
        color: ['#3398DB'], // 整体颜色
        title: {
          text: '账本处理笔数/(笔)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '50px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          data: this.xData,
          // name: '时间',
          nameTextStyle: {
            color: '#222B45'
          },
          axisLabel: { // 轴文
            color: '#9CA0AB'
          },
          axisLine: { // 轴线
            lineStyle: { color: '#FFFFF' }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#9CA0AB'
          }
        },
        series: [
          {
            name: '账本处理笔数/(笔)',
            type: this.processingType,
            barWidth: '20px',
            // showBackground: true,
            itemStyle: {
              borderRadius: 7,
              borderColor: '#fff',
              borderWidth: 2,
              color: '#00C3B9'
            },
            label: {
              show: true,
              position: 'top'
              // valueAnimation: true // 不加数字变化动画，因为初始化时出现undefind
            },
            // data: [10, 52, 200, 334, 390, 330, 220]
            data: this.yData1
          }
        ]
      }
      // console.log(this.options)
    }
  }
}
</script>

<style lang="scss">
    .echart-comp {
        height: 100%;
        position: relative;
        &> .nodata{
            position: absolute;
            top: 50%;
            left: 40%;
            z-index: 2;
            color: #999;
        }
    }
</style>
