<template>
    <div class="echart-comp" v-loading="loading">
        <!--折线-->
        <div v-if="!this.datas || this.datas.length === 0" class="nodata">暂无数据</div>
        <echart width="100%" height="100%" :options="options"></echart>
    </div>
</template>

<script>
import Echart from '@/components/Echart/index.vue'

export default {
  name: 'bar-chart',
  components: {
    Echart
  },
  props: {
    datas: {
      type: Array,
      default: () => []
    },
    ledgerTypeChart: {
      type: String,
      default: () => 'bar'
    }
  },
  data() {
    return {
      options: {},
      xData: [],
      yData: [],
      pieData: [],
      pieData1: [],
      pieData2: [],
      loading: false
    }
  },
  computed: {},
  watch: {
    datas: {
      handler() {
        this.getOptions()
      },
      deep: true,
      immediate: true
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getOptions() {
      if (this.ledgerTypeChart === 'bar') {
        this.initBar()
      } else if (this.ledgerTypeChart === 'pie1') {
        this.initPie1()
      } else if (this.ledgerTypeChart === 'pie2') {
        this.initPie2()
      } else if (this.ledgerTypeChart === 'pie3') {
        this.initPie3()
      } else if (this.ledgerTypeChart === 'pie4') {
        this.initPie4()
      } else if (this.ledgerTypeChart === 'pie5') {
        this.initPie5()
      } else {
        this.initBar()
      }
    },
    initPie1() {
      var pieData = []
      // 整理数据返回
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          let obj = {}
          obj.value = item.value ? item.value : 0
          obj.name = item.key
          pieData.push(obj)
        })
        this.pieData = pieData
      }
      this.options = {
        title: {
          text: '账本类型数量/(个)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          type: 'plain',
          orient: 'vertical',
          top: '12%',
          left: 'left'
        },
        series: [
          {
            name: '账本类型',
            type: 'pie',
            left: '40',
            radius: ['40%', '70%'],
            minAngle: 15,
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center',
              formatter: [
                '{b}',
                '{c}'
              ].join('\n\n')
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: this.pieData
          }
        ],
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#804040']
      }
    },
    initPie2() {
      var pieData = []
      // 整理数据返回
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          let obj = {}
          obj.value = item.value ? item.value : 0
          obj.name = item.key
          pieData.push(obj)
        })
        this.pieData = pieData
      }
      this.options = {
        title: {
          text: '账本类型数量/(个)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: '15%',
          left: 'left'
        },
        series: [
          {
            name: '账本类型',
            type: 'pie',
            left: '100',
            radius: ['50%', '80%'],
            minAngle: 15,
            stillShowZeroSum: true,
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'center',
              formatter: [
                '{b}',
                '{c}'
              ].join('\n\n')
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: this.pieData
          }
        ],
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#804040']
      }
    },
    initPie3() {
      var pieData = []
      // 整理数据返回
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          let obj = {}
          obj.value = item.value ? item.value : 0
          obj.name = item.key
          pieData.push(obj)
        })
        this.pieData = pieData
      }
      this.options = {
        title: {
          text: '账本类型数量/(个)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '账本类型',
            type: 'pie',
            left: '40',
            radius: ['30%', '80%'],
            minAngle: 15,
            stillShowZeroSum: true,
            avoidLabelOverlap: true,
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            labelLine: {
              show: true
            },
            data: this.pieData
          }
        ],
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#804040']
      }
    },
    initPie4() {
      var pieData = []
      // 整理数据返回
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          let obj = {}
          obj.value = item.value ? item.value : 0
          obj.name = item.key
          pieData.push(obj)
        })
        this.pieData = pieData
      }
      this.options = {
        title: {
          text: '账本类型数量/(个)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '账本类型',
            type: 'pie',
            radius: ['30%', '80%'],
            minAngle: 15,
            stillShowZeroSum: true,
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              },
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 10,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: this.pieData
          }
        ],
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#804040']
      }
    },
    initPie5() {
      var pieData1 = []
      var pieData2 = []
      // 整理数据返回
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          if (JSON.parse(item.value) > 1000) {
            let obj = {}
            obj.value = item.value
            obj.name = item.key
            pieData1.push(obj)
          } else {
            let obj = {}
            obj.value = item.value ? item.value : 0
            obj.name = item.key
            pieData2.push(obj)
          }
        })
        this.pieData1 = pieData1
        this.pieData2 = pieData2
      }
      this.options = {
        title: {
          text: '账本类型数量/(个)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '账本类型',
            type: 'pie',
            selectedMode: 'single',
            radius: ['0%', '40%'],
            top: 25,
            label: {
              position: 'inner',
              fontSize: 12,
              color: '#ffffff'
            },
            labelLine: {
              show: false
            },
            data: this.pieData2
          },
          {
            name: '账本类型',
            type: 'pie',
            radius: ['50%', '75%'],
            top: 25,
            labelLine: {
              length: 30
            },
            label: {
              formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c} ',
              backgroundColor: '#F6F8FC',
              borderColor: '#8C8D8E',
              borderWidth: 1,
              borderRadius: 4,
              rich: {
                a: {
                  color: '#6E7079',
                  lineHeight: 22,
                  align: 'center'
                },
                hr: {
                  borderColor: '#8C8D8E',
                  width: '100%',
                  borderWidth: 1,
                  height: 0
                },
                b: {
                  color: '#4C5058',
                  fontSize: 14,
                  fontWeight: 'bold',
                  lineHeight: 33
                },
                per: {
                  color: '#fff',
                  backgroundColor: '#4C5058',
                  padding: [3, 4],
                  borderRadius: 4
                }
              }
            },
            data: this.pieData1
          }
        ],
        color: ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#804040']
      }
    },
    initBar() {
      this.xData = []
      this.yData = []
      var yData1 = []
      // 整理数据返回
      if (this.datas && this.datas.length > 0) {
        this.datas.forEach((item, index) => {
          this.xData.push(item.key)
          yData1.push(item.value ? item.value : 0)
        })
        this.yData = yData1
      }
      this.options = {
        title: {
          text: '账本类型数量/(个)',
          textStyle: {
            color: '#222B45'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: '3%',
          right: '100px',
          bottom: '3%',
          containLabel: true
        },
        xAxis: { // 横
          type: 'value',
          nameTextStyle: {
            color: '#222B45'
          },
          axisLabel: {
            color: '#9CA0AB'
          }
        },
        yAxis: {
          type: 'category',
          inverse: true,
          nameTextStyle: {
            color: '#9CA0AB'
          },
          axisLabel: {
            color: '#9CA0AB'
          },
          splitLine: { // 分割线配置
            show: false,
            lineStyle: {
              color: 'rgba(219,225,255,1)'
            }
          },
          axisLine: {
            lineStyle: { color: '#FFFFFF' }
          },
          data: this.xData
        },
        series: [
          {
            type: 'bar',
            barWidth: '20px',
            // showBackground: true,
            itemStyle: {
              borderRadius: 7,
              borderColor: '#fff',
              borderWidth: 2,
              color: '#00C3B9'
            },
            label: {
              show: true,
              position: 'right'
              // valueAnimation: true // 不加数字变化动画，因为初始化时出现undefind
            },
            data: this.yData
          }
        ]
      }
      // console.log(this.options)
    }
  }
}
</script>

<style lang="scss">
    .echart-comp {
        height: 100%;
        position: relative;
        &> .nodata{
            position: absolute;
            top: 50%;
            left: 40%;
            z-index: 2;
            color: #999;
        }
    }
</style>
